<template>
    <div>

        <div class="text-center mt-5 " v-if="cargando">
            <div class="spinner-border text-primary spinner-border-sm" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <br>
            Cargando ...
        </div>

        <div v-if="!cargando" class="col-md-10 offset-md-1"> 
            <div class="d-flex flex-row bd-highlight mb-2">
                <div class="me-3">
                    <img  style="max-height: 100px; border: solid 5px rgb(255, 255, 255); border-radius: 15px " class="float-start border-3" :src="archivospublicos+'/personas/'+infopersona.foto" alt="">
                </div>
                <div class=" ">
                    <span style="font-size: 11px;" class="titulosg">NOMBRE</span> <br>
                    <span class="h4 titulosg text-uppercase">{{infopersona.nombres}} {{infopersona.apellidos}}</span> <br>
                    
                    <span  v-if="infopersona.estado==1" class="badge bg-success">Estado Activo</span>
                    <span  v-if="infopersona.estado==0" class="badge bg-secondary">Estado Inactivo</span> 
                    <div class="mt-3">
                        <!-- <button  class="btn btn-outline-primary btn-sm" data-bs-toggle="modal" data-bs-target="#modalcargarfoto"> <i class="fas fa-camera"></i> Cambiar Foto   </button> -->
                    </div>
                </div>
            </div>

            <div class="row mb-2">
                <div class="col-md-8">
                    <div class="p-3 bg-white card" style="border-radius: 10px;">

                                <table class="table table-sm table-borderless mb-0 ">
                                    <tbody>
                                    <tr>                                   
                                        <td class="text-secondary">Identificación:</td>
                                        <td> {{infopersona.identificacion}}</td>                                
                                    </tr>
                                    <tr>
                                        <td class="text-secondary">Tipo de Identificación:</td>
                                        <td>  {{tipodocumento[infopersona.tipoidentificacion]}} </td>
                                    </tr>
                                    <tr>
                                        <td class="text-secondary"> Fecha de Expedición: </td>
                                        <td> {{infopersona.fechaexpedicion}}  </td>
                                    </tr>
                                    <tr>
                                        <td class="text-secondary"> Lugar de Expedición: </td>
                                        <td> {{infopersona.ciudadexpedicion.nombreciudad}}   {{infopersona.ciudadexpedicion.nombredepartamento}}  </td>
                                    </tr>
                                    <tr>                      
                                        <td class="text-secondary">Fecha de Nacimiento: </td>
                                        <td>{{infopersona.fechanacimiento}}   </td>
                                    </tr>
                                    <tr>
                                        <td class="text-secondary">Lugar de Nacimiento:</td>
                                        <td>{{infopersona.ciudadnacimiento.nombreciudad}} {{infopersona.ciudadnacimiento.nombredepartamento}} </td>
                                    </tr>
                                    <tr v-if="infopersona.personascategoria_idpersonascategoria != 4">
                                        <td class="text-secondary">Estado Civil:</td>
                                        <td> {{ estadocivil[infopersona.estadocivil] }}</td>
                                    </tr>

                                    </tbody>
                                </table>

                                <span class="text-secondary mt-2">Composición Familiar:</span> 
                                {{infopersona.composicionfamiliar}}

                    </div>
                </div>
                <div class="col-md-4">

                    <div style="border-radius: 10px; background-color: rgb(239, 239, 243);" class="p-2 ps-3 mb-2">
                        <i class="fas fa-tachometer-alt"></i>  Edad: <br>
                        <span class="titulosg h5"> {{  edad(infopersona.fechanacimiento ) }} </span>
                    </div>

                    <div style="border-radius: 10px; background-color: rgb(239, 239, 243);" class="p-2 ps-3 mb-2">
                        <i class="fab fa-discourse"></i> Tipo y Grupo de Sangre: <br>
                        <span class="titulosg h5"> {{infopersona.tiposangre}}  </span>
                    </div>

                    <div style="border-radius: 10px; background-color: rgb(239, 239, 243);" class="p-2 ps-3">
                        <i class="fas fa-venus-mars"></i>   Sexo: <br>
                        <span class="titulosg h5" v-if="infopersona.genero=='H'">Hombre</span>
                        <span class="titulosg h5" v-if="infopersona.genero=='M'">Mujer</span>
                    </div>
 
                </div>
            </div>



            <div class="p-4 bg-white card rounded mb-2">
       
                    <span class="titulosg h5">
                      Datos de Contacto
                    </span>
                        <table class="table table-borderless table-sm  mb-0">
                        <tbody>
                            <tr>
                                <td class="text-secondary">Celular:</td>
                                <td >
                                    {{infopersona.celular}}
                                    <a title="escribir" :href="'https://wa.me/57'+infopersona.celular" target="_blank" class="ml-2"> <i class="fab fa-whatsapp text-success fa-lg "></i> </a>
                                    <a title="llamar" :href="'tel:'+infopersona.celular" class="ml-2"> <i class="ms-2 fas fa-mobile-alt fa-lg text-black"></i> </a>                                                                      
                                </td>                                
                            </tr>
                            <tr>
                                <td class="text-secondary">Teléfono Fijo:</td>
                                <td> {{infopersona.telefono}} <a title="llamar" :href="'tel:'+infopersona.telefono" class="ml-2"> <i class="ms-2 fas fa-mobile-alt fa-lg text-black"></i> </a></td>
                            </tr>
                            <tr>
                                <td class="text-secondary">Correo Electónico:</td>
                                <td >{{infopersona.email}}</td>
                            </tr>

                            <tr>
                                <td class="text-secondary">Dirección:</td>
                                <td>{{infopersona.direccion}}</td>
                            </tr>
                            <tr>
                                <td class="text-secondary">Barrio:</td>
                                <td> {{infopersona.barrio}}</td>
                            </tr>
                            <tr>
                                <td class="text-secondary">Ciudad:</td>
                                <td>{{infopersona.ciudadresidencia.nombreciudad}}    {{infopersona.ciudadresidencia.nombredepartamento}} </td>
                            </tr>
                            <tr>
                                <td class="text-secondary">Estrato:</td>
                                <td>{{infopersona.estrato}}</td>
                            </tr>
                        </tbody>
                        </table>
            </div>

            <div  style="min-height: 70px;" class="card p-3 mb-2">

              <img src="/images/seguridadfirma.png" class="position-absolute img-fluid" alt="" style="top: 0px;left:0px;">
              <img v-if="infopersona.firma" :src="'/storage/personas/firmas/'+infopersona.firma" class="img-fluid" alt="">

                  <span class="text-secondary">Firma</span>  
                  <div>
                      <span v-if="infopersona.autorizacionfirma==1" class="badge text-secondary" style="background-color: rgb(192, 238, 207);">Autorizado </span>
                  </div>
            </div>
 
            <div class="card p-4 mb-2">
                        <strong>Observaciones:</strong>  
                        {{infopersona.observacion}}
            </div>

            <div class="row mb-2">
                    <div class="col-md-6" style="font-size: 10px;">
                        <div class="alert alert-light">
                        <i class="far fa-calendar-alt"></i> <strong class="me-3">  Fecha de Registro:</strong>   <span class="text-capitalize">{{ formatoFecha(infopersona.created_at) }}</span> <br>
                        </div>
                    </div>
                    <div class="col-md-6"  style="font-size: 10px;">
                        <div class="alert alert-light">
                         <i class="far fa-calendar-alt"></i>  <strong class="me-3"> Actualizado: </strong>  <span class="text-capitalize">{{ formatoFecha(infopersona.updated_at) }}</span>
                        </div>
                    </div>
            </div>

        </div> <!-- fin div carga-->


        <!-- Modal -->
        <div class="modal fade" id="modalcargarfoto" tabindex="-1" role="dialog" aria-labelledby="modalcargarfotoLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <form v-on:submit.prevent="updatefoto">
                  <div class="modal-body">
                      
                        <div  class="mt-3 rounded-circle border border-5 border-light" :style="'background-image: url('+render+'); margin: auto;  width: 150px; height: 150px;  background-size: 150% '">
                        </div>
                        <label for="" class="form-label">Nueva Imagen de perfil:</label>
                        <input type="file" name="image" @change="getImage" accept="image/*" required class="form-control">
                    
                        <div class="mt-2 alert alert-success" v-if="respuestacarga">
                            {{respuestacarga}}
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                        <button type="submit" class="btn btn-primary"> <i class="fas fa-upload"></i> Cargar</button>
                    </div>
                </form>
                </div>
              </div>
        </div>
 
  <!-- {{token}}    -->

    </div>
</template>
<script>

import { ref, onMounted } from 'vue'
import axios from 'axios'
import moment from 'moment'

export default {
    setup(props) {
        
        const infopersona = ref([])

        const estadocivil = ref(['Soltero/a', 'Casado/a', 'En Relación ( más de 1 Año de noviazgo)', 'Comprometido/a', 'Unión libre o unión de hecho', 'Separado/a', 'Divorciado/a', 'Viudo/a.'])
        const tipodocumento = ref(['-', 'Cédula de Ciudadanía', 'Cédula de Extrangería', 'Pasaporte', 'Permiso Especial de Trabajo', 'RC - Registro Civil', 'TI - Tarjeta de identidad', 'NIP - Número de Identificación Personal', 'NUIP - Número Único de Identificación personal', 'NES - Número de Secretaría', 'PV - Pasaporte vigente', 'MS - Menor sin identificación'])
        const cargando = ref(true)

        const imagen = ref(null)
        const render = ref(null)

        const token = ref(localStorage.getItem('token'))

        const respuestacarga = ref(null)

        const edad = (date) => {
            return moment(new Date()).diff(date, 'years') + ' Años'
        }

        const formatoFecha = (date) => {
            return moment(date).format('MMMM D [de] YYYY, h:mm:ss a');
        }

        const consultarinformacion = () => {
            axios.get('/api/infopersonaautenticada').then( response => {
                    cargando.value = false;
                    infopersona.value = response.data;
                })
        }

        const getImage = (event) => {
            //Asignamos la imagen a  nuestra data
            imagen.value = event.target.files[0];
            render.value = URL.createObjectURL(event.target.files[0]);
            respuestacarga.value = "";

        }

        const updatefoto = () => {
            var data = new FormData();
            data.append('foto', imagen.value);
            data.append('fotoanterior', infopersona.value.foto);
            data.append('_method', 'PUT');
            data.append('id', infopersona.value.idpersonas);
            axios.post('/api/cambiarimagen', data)
                .then(response => {
                    infopersona.value.foto = response.data;
                    respuestacarga.value = "Imagen cargada Correctamente";
                })
        }


        onMounted(() => {
            consultarinformacion()
        })

        return {
            infopersona,
            estadocivil,
            tipodocumento,
            cargando,
            imagen,
            render,
            respuestacarga,
            token,

            //Funciones
            edad,
            formatoFecha,
            getImage
            }
    } 
}
</script>